<template>
  <div id="misc">
    <div class="page-title text-center px-5 center-element">
      <h1 class="font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">Votre inscription est terminée.</span>
      </h1>
      <h1 class="font-weight-semibold text--primary d-flex align-center justify-center">
        <span class=""> Créez maintenant votre authentification à deux facteurs.</span>
      </h1>
      <div class="d-flex justify-center">
        <v-img
          max-width="400"
          max-height="400"
          :src="require(`@/assets/images/pictogram/registerComplete.png`)"
        ></v-img>
      </div>
      <v-btn x-large rounded color="primary" to="login" class="mt-15">
        Définir mon code confidentiel
        <v-icon right> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from "@mdi/js"

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
.center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
